import { graphql, useStaticQuery } from "gatsby"

const useTeam = () => {
  const response = useStaticQuery(
    graphql`
        query teamInfo {
          strapiTeam {
            team_1_name
            team_1_nickname
            team_1_description
            team_1_image {
                sharp: childImageSharp {
                    fluid( maxWidth: 1200 ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
              team_2_name
              team_2_nickname
              team_2_description
              team_2_image {
                  sharp: childImageSharp {
                      fluid( maxWidth: 1200 ) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              team_3_name
              team_3_nickname
              team_3_description
              team_3_image {
                  sharp: childImageSharp {
                      fluid( maxWidth: 1200 ) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              team_4_name
              team_4_nickname
              team_4_description
              team_4_image {
                  sharp: childImageSharp {
                      fluid( maxWidth: 1200 ) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
          }
        }`
  )
  let teamInfo = response.strapiTeam;
  //TODO Load Projects data

  return teamInfo;
}

export default useTeam;
