import React from "react";
import team1 from "../assets/images/team-1-1.jpg";
import team2 from "../assets/images/team-1-2.jpg";
import team3 from "../assets/images/team-1-3.jpg";
import team4 from "../assets/images/team-1-4.jpg";
import { Link } from "gatsby"
import useTeam from "../Hooks/useTeam"
import Img from "gatsby-image"
const TeamRolteca = () => {
  const datosEquipo = useTeam();
  return (
    <section className="team-one team-one__become-teacher">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Conoce a nuestros  <br/> colaboradores
          </h2>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <Img fluid={{ ...datosEquipo.team_1_image.sharp.fluid, aspectRatio: 1 / 1  }} alt="" />
              </div>
              <div className="team-one__content">
                <h3 className="team-one__name">
                  <Link to="/nosotros">{datosEquipo.team_1_name}</Link>
                </h3>
                <p className="team-one__designation">{datosEquipo.team_1_nickname}</p>
                <p className="team-one__text text-justify">
                  {datosEquipo.team_1_description}
                </p>
              </div>
              <div className="team-one__social text-center">
                <a href="https://twitter.com/OshyTech" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://github.com/oshytech" rel="noreferrer">
                  <i className="fab fa-github"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCAZgvndcorh9hqGg8vg_8Rw" rel="noreferrer">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <Img fluid={{ ...datosEquipo.team_2_image.sharp.fluid, aspectRatio: 1 / 1  }} alt="" />
              </div>
              <div className="team-one__content">
                <h3 className="team-one__name">
                  <Link to="/nosotros">{datosEquipo.team_2_name}</Link>
                </h3>
                <p className="team-one__designation">{datosEquipo.team_2_nickname}</p>
                <p className="team-one__text text-justify">
                  {datosEquipo.team_2_description}
                </p>
              </div>
              <div className="team-one__social text-center">
                <a href="https://twitter.com/inavarro1138" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <Img fluid={{ ...datosEquipo.team_3_image.sharp.fluid, aspectRatio: 1 / 1  }} alt="" />
              </div>
              <div className="team-one__content">
                <h3 className="team-one__name">
                  <Link to="/nosotros">{datosEquipo.team_3_name}</Link>
                </h3>
                <p className="team-one__designation">{datosEquipo.team_3_nickname}</p>
                <p className="team-one__text text-justify">
                  {datosEquipo.team_3_description}
                </p>
              </div>
              <div className="team-one__social">
                <a href="https://twitter.com/unmossodemagat1" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <Img fluid={{ ...datosEquipo.team_4_image.sharp.fluid, aspectRatio: 1 / 1  }} alt="" />
              </div>
              <div className="team-one__content">
                <h3 className="team-one__name">
                  <Link to="/nosotros">{datosEquipo.team_4_name}</Link>
                </h3>
                <p className="team-one__designation">{datosEquipo.team_4_nickname}</p>
                <p className="team-one__text text-justify">
                  {datosEquipo.team_4_description}
                </p>
              </div>
              <div className="team-one__social">
                <a href="#none">
                  <i className="far fa-question-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamRolteca;
